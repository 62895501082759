<template>
  <!-- 服务项目/家电管理 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />

    <div class="main-content">
      <div class="body">
        <my-table
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          :pagination="false"
        >
          <template slot="cover" slot-scope="record">
            <div class="cover">
              <img class="img" :src="record.cover" alt="cover" />
            </div>
          </template>

          <a-table
            class="inner-table"
            slot="expandedRowRender"
            slot-scope="innerRecord"
            :columns="innerColumns"
            :data-source="innerRecord.info"
            :pagination="false"
            :row-key="(record) => record.infoId"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onEquipmentChange,
              onSelect: onEquipmentSelect,
              onSelectAll: onEquipmentSelectAll
            }"
          >
            <template slot="cover" slot-scope="records">
              <div class="cover">
                <img class="img" :src="records.cover" alt="cover" />
              </div>
            </template>
            <template slot="price" slot-scope="records">
              <a-statistic prefix="￥" :value="records.price" />
            </template>
          </a-table>
        </my-table>

        <div class="add-button-box">
          <a-button type="primary" @click="addMainServiceEquipment">
            确认添加
          </a-button>
          <div class="text-box">
            <a-icon type="info-circle" />
            <span class="text">默认家电设备为下架状态，添加后自行上架</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let categoryId = ''
let bcid = ''
let areaId = []
let serviceEquipment = []
export default {
  data() {
    return {
      title: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '服务名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '缩略图',
          scopedSlots: { customRender: 'cover' }
        }
      ],
      innerColumns: [
        {
          title: '排序',
          dataIndex: 'sort',
          width: 60
        },
        {
          title: '设备名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '设备属性',
          dataIndex: 'subtitle',
          width: 300
        },
        {
          title: '缩略图',
          width: 100,
          scopedSlots: { customRender: 'cover' }
        },
        {
          title: '默认售价',
          scopedSlots: { customRender: 'price' }
        }
      ],
      selectedRowKeys: [],
      loading: true,
      expandedLoading: true
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    categoryId = query.id
    bcid = query.bcid
    areaId = query.areaId[query.areaId.length - 1]
    if(areaId){
      this.getBcAllServingInfo()
    }else{
      this.getPublicServiceItemsEquipmentFilter()
    }
    
  },
  methods: {
    getPublicServiceItemsEquipmentFilter() {
      // 获取公共数据服务项目
      const data = {
        bcid: bcid,
        categoryId: categoryId
      }
      this.$axios.getPublicServiceItemsEquipmentFilter(data).then((res) => {
        this.dataSource = res.data.data.data
        this.loading = false
      })
    },
     getBcAllServingInfo() {
      // 获取城市服务公共数据服务项目
      const data = {
        bcid: bcid,
        categoryId: categoryId,
        areaId:areaId
      }
      this.$axios.getBcAllServingInfo(data).then((res) => {
        this.dataSource = res.data.data.data
        this.loading = false
      })
    },
    onEquipmentReset() {
      // 初始化选择
      serviceEquipment = []
      this.selectedRowKeys = []
    },
    onEquipmentChange(selectedRowKeys) {
      // 监听选择发生变化时
      console.log(selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    onEquipmentSelect(record, selected) {
      // 监听选择/取消选择
      if (selected) {
        const item = {
          servingId: record.servingId,
          infoId: record.infoId
        }
        serviceEquipment.push(item)
      } else {
        const i = serviceEquipment.findIndex((f) => f.infoId === record.infoId)
        if (i > -1) {
          serviceEquipment.splice(i, 1)
        }
      }
    },
    onEquipmentSelectAll(selected, selectedRows, changeRows) {
      // 监听选择列/取消选择列
      changeRows.forEach((e) => {
        this.onEquipmentSelect(e, selected)
      })
    },
    addMainServiceEquipment() {
      // 添加对应分类的设备
      if (!serviceEquipment.length) {
        this.$message.error('请先选择要添加的设备')
        return
      }
      const hide = this.$message.loading('请稍后')
      const data = {
        bcid: bcid,
        categoryId: categoryId,
        Info: serviceEquipment,
        areaId: areaId || undefined
      }
      if (areaId) {
        this.$axios.getAddInfo(data).then(() => {
          hide()
          this.$message.success('添加成功')
          this.onEquipmentReset()
          this.getPublicServiceItemsEquipmentFilter()
        })
      } else {
        this.$axios.addMainServiceEquipment(data).then(() => {
          hide()
          this.$message.success('添加成功')
          this.onEquipmentReset()
          this.getPublicServiceItemsEquipmentFilter()
        })
      }
    }
  }
}
</script>

<style scoped>
.body {
  height: 100%;
  background-color: #fff;
}

.ant-steps,
.ant-form {
  width: 1050px;
  margin: 0 auto;
}

.ant-form {
  margin-top: 110px;
}

.ant-form .ant-btn {
  margin-left: 25em;
}

.cover {
  width: 50px;
  height: 50px;
}

.ant-table-wrapper {
  border: 1px solid #ebebeb;
}

.inner-table {
  background-color: #fafafa;
}

.add-button-box {
  display: flex;
  align-items: center;
  margin: 32px 0 32px 32px;
}

.add-button-box .anticon {
  margin: 0 4px 0 16px;
}

.add-button-box .text {
  font-weight: 500;
  color: #595959;
}
</style>
